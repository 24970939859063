import { Component, OnInit } from '@angular/core';
import { ContactCanvasService, IPresence } from '../contact-canvas.service';
import { LoggerService } from '../logger.service';

@Component({
  selector: 'app-person-icon',
  templateUrl: './person-icon.component.html',
  styleUrls: ['./person-icon.component.scss']
})
export class PersonIconComponent implements OnInit {
  presence: IPresence;
  reason: string;
  username: string;
  presenceChangedTime: Date;
  totalStartDateTimeInMillisecond: number;

  constructor(public contactCanvasService: ContactCanvasService, private _loggerService: LoggerService) { }


  ngOnInit() {
    this._loggerService.logger.logDebug('start: ngOnInit of PersonIconComponent');
    this.contactCanvasService.getCurrentStartDateTime().subscribe(dateTime => this.totalStartDateTimeInMillisecond = dateTime);
    this.contactCanvasService.getCurrentPresence().subscribe(presence => {
      this.presence = presence;
      this.presenceChangedTime = new Date();
    });
    this.contactCanvasService.getCurrentReason().subscribe(reason => this.reason = reason);

    this.contactCanvasService.getUserDetails().then(user => {
      if (user.firstName) {
        this.username = user.firstName;
      } else if (user.lastName) {
        this.username = user.lastName;
      } else {
        this.username = user.email;
      }
    });


    this._loggerService.logger.logDebug('complete: ngOnInit of PersonIconComponent');
  }
}
