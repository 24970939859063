import { Component, OnInit } from '@angular/core';
import { ContactCanvasService, IPresence } from '../contact-canvas.service';
import { LoggerService } from '../logger.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-presence-menu',
  templateUrl: './presence-menu.component.html',
  styleUrls: ['./presence-menu.component.css']
})
export class PresenceMenuComponent implements OnInit {
  presences: IPresence[] = [];
  selectedPresence: IPresence;
  reasonsToDisplay: string[] = [];
  currentPresence: IPresence;
  currentReason: string;
  showReasons = false;
  logoutReasons: string[] = [];
  logoutSelected = false;
  presenceMenuVisible: Boolean = false;
  hidePresenceMenuTimeout = -1;
  hasReceivedFirstPresence$: Observable<boolean>;
  logoutMenuPresence: IPresence;

  constructor(public contactCanvasService: ContactCanvasService, private _loggerService: LoggerService) { }

  ngOnInit() {
    this._loggerService.logger.logDebug('start: ngOnInit of PresenceMenuComponent');
    this.contactCanvasService.getPresences().subscribe(presences => this.presences = presences);
    this.contactCanvasService.getLogoutReasons().subscribe(reasons => this.logoutReasons = reasons);
    this.contactCanvasService.getCurrentPresence().subscribe(presence => {
      this.currentPresence = presence;
      this.setPresenceMenuVisible(false);
    });
    this.contactCanvasService.getCurrentReason().subscribe(reason => this.currentReason = reason);
    this.hasReceivedFirstPresence$ = this.contactCanvasService.hasReceivedFirstPresence$;
    this.contactCanvasService.getLogoutMenuPresence().subscribe(logoutMenuPresence => this.logoutMenuPresence = logoutMenuPresence);
    this._loggerService.logger.logDebug('completed: ngOnInit of PresenceMenuComponent');
  }

  onPresenceSelected(presence: IPresence) {
    this._loggerService.logger.logDebug('start: clicked/selected presence callback');
      if (presence.reasonCodes.length > 0) {
          this.logoutSelected = false;
          this.selectedPresence = presence;
          this.reasonsToDisplay = presence.reasonCodes;
          this.showReasons = true;
      } else {
        if (presence !== this.currentPresence) {
          this.setPresenceMenuVisible(false);
          this.contactCanvasService.menuIsProcessing = true;
          this.contactCanvasService.setPresence(presence);
        }
      }
      this._loggerService.logger.logDebug('end: clicked/selected presence callback');
  }

  onPresenceSelectedKey(event: KeyboardEvent, presence: IPresence) {
    if(event.key === "Enter") {
      this.onPresenceSelected(presence);
    }
  }

  onReasonSelected(reason: string) {
    this._loggerService.logger.logDebug('start: clicked/selected Reason callback');
    this.setPresenceMenuVisible(false);
    this.showReasons = false;
    if (this.logoutSelected) {
      this.contactCanvasService.logout(reason);
    } else {
      if (reason !== this.currentReason) {
        this.contactCanvasService.menuIsProcessing = true;
        this.contactCanvasService.setPresence(this.selectedPresence, reason);
      }
    }
    this._loggerService.logger.logDebug('end: clicked/selected Reason callback');
  }

  onReasonSelectedKey(event: KeyboardEvent, reason: string) {
    if(event.key === "Enter") {
      this.onReasonSelected(reason);
    }
  }

  logout() {
    this._loggerService.logger.logDebug('start: Logout click  callback');
    if (this.logoutReasons && this.logoutReasons.length > 0) {
      this.logoutSelected = true;
      this.reasonsToDisplay = this.logoutReasons;
      this.showReasons = true;
    } else {
      this.contactCanvasService.logout();
    }
    this._loggerService.logger.logDebug('end: Logout click  callback');
  }

  logoutKey(event: KeyboardEvent) {
    if(event.key === "Enter") {
      this.logout();
    }
  }

  displayPresence(presence: IPresence) {
    return (!this.currentPresence.interactionPresence && presence.displayPresence)
      || (this.currentPresence.interactionPresence && presence.displayPresenceOnInteraction);

    return (presence.displayPresence && !(this.currentPresence.interactionPresence || this.currentPresence.displayPresenceOnInteraction))
      || (presence.displayPresenceOnInteraction
        && (this.currentPresence.interactionPresence || this.currentPresence.displayPresenceOnInteraction));
  }

  displayLogout() {
    if (this.logoutMenuPresence.displayPresenceOnInteraction) {
      return true;
    } else {
      return (!this.currentPresence.interactionPresence);
    }
  }

  getReasonClasses(reason: string) {
    const grayOut = !this.logoutSelected && this.currentPresence === this.selectedPresence && reason === this.currentReason;
    return grayOut ? 'menuItem grayOut' : 'menuItem';
  }

  getPresenceClasses(presence: IPresence) {
    const grayOut = this.currentPresence === presence;
    return grayOut ? 'menuItem grayOut' : 'menuItem';
  }


  clearHidePresenceMenuTimeout() {
    window.clearTimeout(this.hidePresenceMenuTimeout);
  }
  setHidePresenceMenuTimeout() {
    this.hidePresenceMenuTimeout = window.setTimeout(() => {
      this.setPresenceMenuVisible(false);
    }, 2000);
  }

  setPresenceMenuVisible(visible: boolean) {
    this.presenceMenuVisible = visible;
    this.showReasons = false;
    this.clearHidePresenceMenuTimeout();
  }

  setPresenceMenukey(event: KeyboardEvent, event2: boolean) {
    if(event.key === "Enter") {
      this.setPresenceMenuVisible(event2);
    }
  }

  showReasonsKey(event: KeyboardEvent) {
    if(event.key === "Enter") {
      this.showReasons = !this.showReasons;
    }
  }
}
