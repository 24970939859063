import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  ERROR_CODE,
  NOTIFICATION_TYPE,
  getConfig,
  getUserDetails,
  initializeComplete,
  logout,
  onPresenceChanged,
  registerGetPresence,
  registerSetPresence,
  sendNotification,
  setAppHeight,
  setAppWidth
} from '@amc-technology/davinci-api';
import { FastAverageColor, FastAverageColorResult } from 'fast-average-color';

import { ConfigurationService } from './configuration.service';
import { Injectable } from '@angular/core';
import { LoggerService } from './logger.service';

//import { IFastAverageColorResult } from 'fast-average-color';

@Injectable()
export class ContactCanvasService {
  public hasReceivedFirstPresence$ = new BehaviorSubject(false);
  public menuIsProcessing: Boolean = false;
  private _logoutPresence: IPresence = {
    presence: 'LoggedOut',
    displayPresence: false,
    icon: 'assets/user_loggedout.png',
    order: Number.MAX_SAFE_INTEGER,
    reasonCodes: [],
    viewOnlyReasonCodes: [],
    displayPresenceOnInteraction: false,
    interactionPresence: false,
    color: '#bdbec0'
  };
  private _logoutMenuPresence: IPresence = {
    presence: 'Logout',
    displayPresence: true,
    icon: 'assets/user_loggedout.png',
    order: Number.MAX_SAFE_INTEGER,
    reasonCodes: [],
    viewOnlyReasonCodes: [],
    displayPresenceOnInteraction: true,
    interactionPresence: false,
    color: '#bdbec0'
  };
  private _presences: IPresence[] = [];
  private _currentPresence: BehaviorSubject<IPresence> = new BehaviorSubject({
    presence: 'Pending',
    displayPresence: false,
    icon: 'assets/user_loggedout.png',
    order: 1,
    reasonCodes: [],
    viewOnlyReasonCodes: [],
    displayPresenceOnInteraction: false,
    interactionPresence: false,
    color: '#bdbec0'
  });
  private _currentReason = new BehaviorSubject('');
  private _currentStartDateTime = new BehaviorSubject(0);
  private _logoutReasons = new BehaviorSubject<string[]>([]);
  fac = new FastAverageColor();
  loggedOut = false;
  _timeout = null;
  timeoutLength = 7000; // If no config, default to 7 seconds

  constructor(private _configurationService: ConfigurationService,
    private _loggerService: LoggerService) { }

  initialize(): void {
    window.addEventListener('storage', this.storageListener);
    registerGetPresence(() => Promise.resolve({ presence: this._currentPresence.value.presence, reason: this._currentReason.value }));
    registerSetPresence((presence: string, reason: string, startDateTime?: string, initiatingApp?: string) => {
      this.menuIsProcessing = false;
      let stateDurationTime = 0;
      if(startDateTime) {
        let now = new Date();
        let currentTime = Date.UTC(now.getFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
        stateDurationTime = Math.round(currentTime - (+startDateTime));

      }
      if (this._timeout) clearTimeout(this._timeout);
      if (!this.loggedOut) { // ignore any presence change that happens after we logout
        const configuredPresence = this.findPresence(presence);
        this._currentPresence.next(configuredPresence);
        this._currentReason.next(reason || '');
        this._currentStartDateTime.next(stateDurationTime);
        if (configuredPresence) {
          if (!reason || configuredPresence.reasonCodes.indexOf(reason) >= 0 || configuredPresence.viewOnlyReasonCodes.indexOf(reason) >= 0) {
            this.setPresence(configuredPresence, reason || '', initiatingApp, true);
            return Promise.resolve();
          }
          return Promise.reject('Invalid reason');
        }
        return Promise.reject('Invalid presence');
      }
  });

    getConfig().then(async (config: any) => {
      if (config && config.variables && config.variables.hasOwnProperty('TimeoutLength')) {
        this.timeoutLength = config.variables.TimeoutLength * 1000;
      }
      for (const key of Object.keys(config.ConfiguredPresences)) {
        if (key === 'variables') { continue; }
        this._presences.push({
          presence: key,
          displayPresence: config.ConfiguredPresences[key].variables.displayPresence || false,
          icon: config.ConfiguredPresences[key].variables.icon,
          order: config.ConfiguredPresences[key].variables.order,
          reasonCodes: config.ConfiguredPresences[key].variables.reasonCodes,
          viewOnlyReasonCodes: (config.ConfiguredPresences[key].variables.viewOnlyReasonCodes)? config.ConfiguredPresences[key].variables.viewOnlyReasonCodes : [],
          displayPresenceOnInteraction: config.ConfiguredPresences[key].variables.displayPresenceOnInteraction || false,
          interactionPresence: config.ConfiguredPresences[key].variables.interactionPresence || false
        });
      }

      // Provide backward compatibility with config.variables.LogoutReasons
      //
      this._logoutMenuPresence.presence = (config.Logout && config.Logout.variables.displayName)? config.Logout.variables.displayName : "Logout";
      this._logoutMenuPresence.reasonCodes = (config.variables.LogoutReasons)? config.variables.LogoutReasons : config.Logout.variables.reasonCodes;
      this._logoutMenuPresence.displayPresenceOnInteraction =
             (config.Logout && config.Logout.variables.displayOnInteractionPresence)? true: false;

      this._logoutReasons.next(this._logoutMenuPresence.reasonCodes);

      this._presences = this._presences.sort((a: any, b: any) =>
        a.order === b.order ? a.presence.localeCompare(b.presence) : a.order - b.order);
      this._presences = await this.addPresenceColors(this._presences);
      this._loggerService.logger.logInformation('GlobalPresence Initialized', ERROR_CODE.APP_INITIALIZED);
    }).catch(err => this._loggerService.logger.logError('Problem with configuration! ' + err));

    initializeComplete(this._loggerService.logger);
  }

  getUserDetails() {
    return getUserDetails();
  }

  async addPresenceColors(presences: IPresence[]) {
    try {
      presences = await Promise.all(presences.map(async presence => {
        const color = await this.getColorForImage(presence.icon);
        presence.color = color.hex;
        return presence;
      }));

      return presences;
    } catch (e) {
      debugger;
      console.error(e);
    }
  }

  getColorForImage(src: string): Promise<FastAverageColorResult> {
    return new Promise((resolve, reject) => {
      const img = document.createElement('img');
      img.style.height = '0';
      img.style.width = '0';
      img.src = src;
      img.onload = () => {
        const color = this.fac.getColorAsync(img);
        img.parentNode.removeChild(img);
        resolve(color);
      };
      document.body.appendChild(img);
    });
  }

  async logout(reason?: string) {
    this.loggedOut = true;
    logout(reason || undefined);
    this._currentPresence.next(this._logoutPresence);
    this._currentReason.next('');
    this._loggerService.logger.logInformation('user logged out', ERROR_CODE.LOGOUT);
    await this._loggerService.logger.pushLogsAsync();
  }
  setPresence(presence: IPresence, reason?: string, initiatingApp?: string, isResponse?: boolean) {

    onPresenceChanged(presence.presence, reason, presence.color, initiatingApp || 'GlobalPresence');
    if(this._timeout) clearTimeout(this._timeout);
    if (!isResponse) {
      this._timeout = setTimeout(() => {
        // Error setting presence
        // Remove progress indicator
        this._loggerService.logger.logError('Global Presence: Timeout: Failed to get a response from CTI to set Presence.');
        sendNotification('Failed to set agent state - Please close this message and try again', NOTIFICATION_TYPE.Error);
        this.menuIsProcessing = false;
    }, this.timeoutLength);
    }
    this._loggerService.logger.logInformation(`presence changed to  ${JSON.stringify(presence)}
    with reason ${reason} and color ${presence.color}`, ERROR_CODE.PRESENCE_CHANGE);
    if (this.hasReceivedFirstPresence$.value === false) {
      this.hasReceivedFirstPresence$.next(true);
    }
  }
  getPresences(): Observable<IPresence[]> {
    return of(this._presences);
  }
  getCurrentPresence(): Observable<IPresence> {
    return this._currentPresence.asObservable();
  }
  getCurrentReason(): Observable<string> {
    return this._currentReason.asObservable();
  }
  getLogoutReasons() {
    return this._logoutReasons.asObservable();
  }
  getLogoutMenuPresence(): Observable<IPresence> {
    return of(this._logoutMenuPresence);
  }
  getCurrentStartDateTime(): Observable<number> {
    return this._currentStartDateTime.asObservable();
  }
  setHeight(height: number) {
    return setAppHeight(height);
  }

  setWidth(width: number) {
    return setAppWidth(width);
  }

  private findPresence(presence: string) {
    return this._presences.find((value: any, index: number, obj: any[]) => value.presence === presence);
  }

  private storageListener = (e: StorageEvent) => {
    switch (e.key) {
      case 'presence':
        const newPresence = this.findPresence(e.newValue);
        if (newPresence) {
          this._currentPresence.next(newPresence);
        }
        break;
      case 'reason':
        if (e.newValue === '' || this._currentPresence.value.reasonCodes.indexOf(e.newValue) || this._currentPresence.value.viewOnlyReasonCodes.indexOf(e.newValue)) {
          this._currentReason.next(e.newValue);
        }
        break;
    }
  }
}

export interface IPresence {
  presence: string;
  displayPresence: boolean;
  icon: string;
  order: number;
  reasonCodes: string[];
  viewOnlyReasonCodes: string[]; // Reason Codes that are meant to only view but not available for agent to select
  displayPresenceOnInteraction: boolean;
  interactionPresence: boolean;
  color?: string;
}
