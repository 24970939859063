import { Component, OnInit, AfterViewChecked, ElementRef } from '@angular/core';
import { ContactCanvasService } from '../contact-canvas.service';
import { LoggerService } from '../logger.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements AfterViewChecked {
  height = 0;
  width = 0;
  constructor(public contactCanvasService: ContactCanvasService, private el: ElementRef, private loggerService: LoggerService) {
    this.loggerService.logger.logDebug('start:constructor of the HomeComponent');
    this.setHeight();
    this.setWidth();
    this.loggerService.logger.logDebug('completed: constructor of the HomeComponent');
  }

  ngAfterViewChecked() {
    this.setHeight();
    this.setWidth();
  }

  private setHeight() {
    const newHeight = this.getHeight();

    if (newHeight !== this.height) {
      this.height = newHeight;
      this.contactCanvasService.setHeight(this.height);
    }
  }
  private getHeight() {
    const child = document.getElementsByTagName('app-person-icon')[0];
    return Math.ceil(Math.max(child ? child.scrollHeight : 0, 30));
  }
  private setWidth() {
    const newWidth = this.getWidth();
    if (newWidth !== this.width) {
      this.width = newWidth;
      this.contactCanvasService.setWidth(newWidth);
    }
  }
  private getWidth() {
    const child = document.getElementsByTagName('app-person-icon')[0];
    return Math.ceil(Math.max(child ? child.scrollWidth + 5 : 0, 200));
  }
}
