import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.css']
})
export class TimerComponent {
  private _updateInterval = -1;
  private _startTime: Date;
  private _millisecondsDateTime: number;
  private difference = 0;
  get startTime() {
    return this._startTime;
  }
  @Input() set startTime(value) {
    this._startTime = value;

    window.clearInterval(this._updateInterval);
    this._updateInterval = window.setInterval(() => this.changeDetectorRef.detectChanges(), 1000);
  }

  @Input() set millisecondsDateTime(value) {
    this._millisecondsDateTime = value;
  }


  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  getTime() {
    if(this._millisecondsDateTime > 0) {
      this.difference = Math.abs(((new Date()).getTime() - this.startTime.getTime()) + (this._millisecondsDateTime));
    }else {
      this.difference = Math.abs((new Date()).getTime() - this.startTime.getTime());
    }
    return this.timeDifferenceToString(this.difference);
  }
  timeDifferenceToString(difference: number) {
    const seconds = Math.floor((difference / 1000) % 60);
    const minutes = Math.floor((difference / (1000 * 60)) % 60);
    const hours = Math.floor(difference / (1000 * 60 * 60));
    return (hours > 0 ? hours.toString().padStart(2, '0') + ':' : '') +
      minutes.toString().padStart(2, '0') + ':' +
      seconds.toString().padStart(2, '0');
  }

}
