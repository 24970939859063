import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { ConfigurationService } from './configuration.service';
import { ContactCanvasService } from './contact-canvas.service';
import { FormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { LoggerService } from './logger.service';
import { PersonIconComponent } from './person-icon/person-icon.component';
import { PresenceMenuComponent } from './presence-menu/presence-menu.component';
import { RouterModule } from '@angular/router';
import { TimerComponent } from './timer/timer.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PresenceMenuComponent,
    PersonIconComponent,
    TimerComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
    ])
  ],
  providers: [
    ConfigurationService,
    {
      // Here we request that configuration loading be done at app-
      // initialization time (prior to rendering)
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigurationService, loggerService: LoggerService, ccService: ContactCanvasService,
      ) =>
        async () => {
          await configService.loadConfigurationData();
          await loggerService.initialize();
          await ccService.initialize();
        },
      deps: [ConfigurationService, LoggerService, ContactCanvasService],
      multi: true
    },
    ContactCanvasService,
    LoggerService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
